<template>
    <div id="control-area">
        <div id="control">
            <div class="floor-layout">
                <img src="img/layout.svg" alt="" srcset="" />

                <div class="button light" style="left: 25%; top: 2%">
                    <img src="img/bulb.svg" alt="" srcset="" />
                </div>
                <div class="button light" style="left: 42%; top: 33%">
                    <img src="img/bulb.svg" alt="" srcset="" />
                </div>
                <div class="button light" style="left: 42%; top: 70%">
                    <img src="img/bulb.svg" alt="" srcset="" />
                </div>

                <div class="button shade" style="left: 0%; top: 10.75%">
                    <img src="img/shade.svg" alt="" srcset="" />
                </div>
                <div class="button shade" style="left: 0%; top: 35%">
                    <img src="img/shade.svg" alt="" srcset="" />
                </div>
                <div class="button shade" style="left: 0%; top: 52%">
                    <img src="img/shade.svg" alt="" srcset="" />
                </div>
                <div class="button shade" style="left: 0%; top: 81%">
                    <img src="img/shade.svg" alt="" srcset="" />
                </div>
                <div class="button shade" style="left: 13%; top: 87%">
                    <img src="img/shade.svg" alt="" srcset="" />
                </div>
            </div>

            <!-- <div class="buttons">
                <div class="button">
                    <span>Raffstore 1</span>
                </div>

                <div class="button">Raffstore 2</div>
            </div>
            <div class="buttons">
                <div class="button">Raffstore 2</div>
                <div class="button">Raffstore 2</div>
            </div>
            <div class="actions">
                <div>Alle hoch</div>
                <div>Alle runter</div>
                <div>Sonnenschutz Süd</div>
                <div>Sonnenschutz West</div>
            </div> -->
        </div>
    </div>
</template>

<script>
// import RelaisButton from './RelaisButton.vue'
// import RunningInfo from './RunningInfo.vue'

export default {
    name: 'Control',

    components: {
        // RelaisButton,
        // RunningInfo,
    },

    methods: {
        reload: function () {
            // this.$store.dispatch('Reload')
        },
    },

    computed: {
        // isOnline() {
        //     return this.$store.getters.IsOnline
        // },
        // isLoading() {
        //     return this.$store.getters.IsLoading
        // },
        // valveStates() {
        //     return this.$store.getters.ValveStates
        // },
        // valveNames() {
        //     return this.$store.getters.ValveNames
        // },
        // allOffDisabled() {
        //     return (
        //         !this.$store.getters.IsActive || this.$store.getters.IsLoading
        //     )
        // },
        // isActive() {
        //     return this.$store.getters.IsActive
        // },
    },
}
</script>

<style lang="scss" scoped>
.floor-layout {
    padding: 20px;
    position: relative;
}

.button {
    display: inline-block;

    position: absolute;

    padding: 10px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    margin: 10px;
    box-shadow: 0px 2px 5px 0px hsla(216.3, 31.1%, 10.9%, 0.3);

    &.light {
        background: rgb(178, 222, 185);
    }

    &.shade {
        background: rgb(60, 108, 144);

        img {
            width: 100%;
        }
    }
}
</style>
