import Vue from "vue";
import Vuex from "vuex";

const debug = process.env.NODE_ENV !== "production";
// const serviceHost = "http://192.168.178.34";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {},
    getters: {},

    strict: debug,
});
