<template>
    <div id="app">
        <header>
            <span class="title">namo</span>
            <nav>
                <ul>
                    <li>EG</li>
                    <li>OG</li>
                    <li>Garten</li>
                </ul>
            </nav>
        </header>

        <control />

        <!-- <status-indicator /> -->
    </div>
</template>

<script>
import Control from './components/Control.vue'

export default {
    name: 'App',
    components: {
        Control,
    },
}
</script>

<style lang="scss">
$header-height: 80px;

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

header {
    height: $header-height;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    color: #000;

    .title {
        text-align: center;
        margin: 10px;
        font-weight: bold;
        display: block;
    }
}

body {
    background-color: rgba(244, 244, 244, 1);
}

#app {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgb(120, 120, 120);
    max-width: 500px;
    padding-top: $header-height;
}

nav {
    ul {
        list-style: none;
        display: flex;

        li {
            text-align: center;
            flex: 1 0 auto;
            margin: 10px;
        }
    }
}

.actions {
    display: flex;
    justify-content: center;
    flex-direction: column;

    > div {
        background: hsl(216.3, 31.1%, 33.9%);
        padding: 20px;
        border-radius: 5px;
        margin: 10px;
        box-shadow: 0px 5px 5px 0px hsla(216.3, 31.1%, 10.9%, 0.3);
    }
}
</style>
